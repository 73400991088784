var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editable
        ? _c(
            "div",
            { staticClass: "col float-right" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-appr-btn", {
                    ref: "appr-btn",
                    attrs: {
                      name: "vendor-assess-appr-btn",
                      editable:
                        _vm.editable && _vm.param.selfInspectionResultId != "",
                      approvalInfo: _vm.approvalInfo,
                    },
                    on: {
                      beforeApprAction: _vm.saveDataAppr,
                      callbackApprAction: _vm.approvalCallback,
                      requestAfterAction: _vm.requestAfterAction,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-tab", {
        attrs: { type: "vertical", tabItems: _vm.tabItems, height: _vm.height },
        on: {
          "update:height": function ($event) {
            _vm.height = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: { param: _vm.param, planData: _vm.planData },
                  on: {
                    "update:param": function ($event) {
                      _vm.param = $event
                    },
                    "update:planData": function ($event) {
                      _vm.planData = $event
                    },
                    "update:plan-data": function ($event) {
                      _vm.planData = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }